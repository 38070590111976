import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, css, styled, withTheme, up } from '@smooth-ui/core-sc';
import HeaderLogo from 'theme/MaxRewardsLogoReversed@2x.png';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  background-color: #192849;
  width: 100%;
  ${up(
    'lg',
    css`
      height: 110px;
    `
  )};
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 110px;
  justify-content: left;
  max-width: 100%;
  width: 100%;
  img {
    height: 78px;
    height: auto;
    width: 136px;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
  width: 100%;

  ${up(
    'lg',
    css`
      flex-direction: column;
      padding-top: 2rem;
    `
  )}
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row justifyContent="center">
                <Col xs={9} lg={3}>
                  <LogoContainer>
                    <Link to="/">
                      <img src={HeaderLogo} alt="Bridgestone Rewards" />
                    </Link>
                  </LogoContainer>
                </Col>
                <Col lg={9}>
                  <HeaderRightContainer>
                    {this.props.children}
                  </HeaderRightContainer>
                </Col>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
